import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild, } from '@angular/core';
import { AmplitudeService } from '@app/api/amplitude/amplitude.service';
import { UserService } from '@app/core/services/user.service';
import { PopoverRef } from '@app/shared/popover';
import { ProfilePopoverComponent } from '../../profile-menu';

@Component({
  selector: 'app-learn-basics-popover',
  templateUrl: './learn-basics-popover.component.html',
  styleUrls: [ './learn-basics-popover.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LearnBasicsPopoverComponent implements AfterViewInit {
  @ViewChild('handle', { read: ElementRef })
  handleRef: ElementRef;
  @ViewChild('content') contentRef: ElementRef<HTMLElement>;

  isFullWidth = false;
  containerWidth = 0;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _popoverRef: PopoverRef<ProfilePopoverComponent>,
    private _amplitudeService: AmplitudeService,
    private _userService: UserService
  ) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const handleWidth =
        this.handleRef.nativeElement.getBoundingClientRect().width;
      const listWidth =
        this.contentRef.nativeElement.getBoundingClientRect().width;
      this.isFullWidth = handleWidth >= listWidth;
      this.containerWidth = this.isFullWidth
        ? handleWidth
        : handleWidth >= listWidth - 8
          ? handleWidth + 8
          : 0;
      this._cdr.markForCheck();
    });
  }

  closePopover(): void {
    this._popoverRef.close();
  }

  amplitudeEventTracking(nameEvent: string): void {
    this._amplitudeService.amplitudeEvent(nameEvent);
    this.closePopover();
  }
}
