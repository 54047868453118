/* eslint-disable @typescript-eslint/naming-convention */
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnInit,
} from '@angular/core';
import { AmplitudeService } from '@app/api/amplitude/amplitude.service';
import { Observable } from 'rxjs';

import { FooterPortalType, LayoutService } from '../layout.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  @HostBinding('class.global-wrapper') isWrapper = true;

  portal$: Observable<FooterPortalType>;
  currentYear: number;

  constructor(
    private _layoutService: LayoutService,
    private _amplitudeService: AmplitudeService
  ) {}

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.portal$ = this._layoutService.getFooterPortal();
  }

  amplitudeTracking(nameEvent: string): void {
    this._amplitudeService.amplitudeEvent(nameEvent);
  }
}
