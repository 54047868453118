import { ViewportScroller } from '@angular/common';
import { NgModule } from '@angular/core';
import { Event, Route, Router, RouterModule, Scroll } from '@angular/router';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { ClientUserAccessGuard } from '@app/core/guards/client-user-access.guard';
import { AdminGuard } from '@app/core/guards/admin.guard';

import { filter } from 'rxjs/operators';

import { LegacyGuard } from './core/guards/legacy.guard';
import { PursuitsFeatureGuard } from './core/guards/pursuits-feature.guard';
import { LayoutType } from './core/models/common.model';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { NotFoundModule } from './modules/not-found/not-found.module';
import { HomePageSelectorGuard } from '@app/core/guards/home-page-selector.guard';
import { ExchangeRatesResolver } from '@app/common/resolvers/exchange-rates.resolver';

const routes: Route[] = [
  {
    path: 'account',
    loadChildren: () =>
      import('./modules/account/account.module').then((m) => m.AccountModule),
    data: {
      layout: LayoutType.Anonym,
    },
  },
  {
    path: 'dashboard',
    canActivate: [ ClientUserAccessGuard ],
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'account-discovery',
    canActivate: [ ClientUserAccessGuard ],
    loadChildren: () =>
      import('./modules/company/company.module').then((m) => m.CompanyModule),
  },
  {
    path: 'private-account-discovery',
    canActivate: [ ClientUserAccessGuard ],
    loadChildren: () =>
      import('./modules/private-company/private-company.module').then(
        (m) => m.PrivateCompanyModule
      ),
  },
  {
    path: 'legacy-redirect/company/:legacyCompanyId',
    canActivate: [ LegacyGuard ],
    component: NotFoundComponent,
  },
  {
    path: 'industry-research',
    canActivate: [ ClientUserAccessGuard ],
    loadChildren: () =>
      import('./modules/industry/industry.module').then(
        (m) => m.IndustryModule
      ),
  },
  {
    path: 'profile',
    canActivate: [ ClientUserAccessGuard ],
    loadChildren: () =>
      import('./modules/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'pursuit/:id',
    canActivate: [ ClientUserAccessGuard, PursuitsFeatureGuard ],
    data: {
      type: 'company',
    },
    loadChildren: () =>
      import('./modules/pursuit/pursuit.module').then((m) => m.PursuitModule),
  },
  {
    path: 'industry-pursuit/:id',
    canActivate: [ ClientUserAccessGuard, PursuitsFeatureGuard ],
    data: {
      type: 'industry',
    },
    loadChildren: () =>
      import('./modules/pursuit/pursuit.module').then((m) => m.PursuitModule),
  },
  {
    path: 'usage-reports',
    canActivate: [ AdminGuard ],
    loadChildren: () =>
      import('./modules/usage-reports/usage-reports.module').then(
        (m) => m.UsageReportsModule
      ),
  },
  {
    path: 'site-settings',
    canActivate: [ AuthGuard ],
    loadChildren: () =>
      import('./modules/company-management/company-management.module').then(
        (m) => m.CompanyManagementModule
      ),
  },
  {
    path: 'system-management',
    canActivate: [ AdminGuard ],
    loadChildren: () =>
      import('./modules/system-management/system-management.module').then(
        (m) => m.SystemManagementModule
      ),
  },
  {
    path: 'administration',
    canActivate: [ AuthGuard, AdminGuard ],
    loadChildren: () =>
      import('./modules/administration/administration.module').then(
        (m) => m.AdministrationModule
      ),
  },
  {
    path: 'payment-required',
    canActivate: [ AuthGuard ],
    loadChildren: () =>
      import('./modules/payment-required/payment-required.module').then(
        (m) => m.PaymentRequiredModule
      ),
  },
  {
    path: 'training',
    canActivate: [ ClientUserAccessGuard ],
    loadChildren: () =>
      import('./modules/learn/learn.module').then((m) => m.LearnModule),
  },
  {
    path: 'added-companies',
    canActivate: [ ClientUserAccessGuard ],
    loadChildren: () =>
      import('@app/modules/common/added-companies/added-companies.module').then(
        (m) => m.AddedCompaniesModule
      ),
  },
  {
    path: 'value-modeler',
    canActivate: [ ClientUserAccessGuard ],
    canLoad: [],
    resolve: {
      exchangeRates: ExchangeRatesResolver,
    },
    loadChildren: () =>
      import('@app/modules/migrated-value-modeler/migrated-value-modeler.module').then(
        (m) => m.MigratedValueModelerModule
      ),
  },
  {
    path: 'journeys',
    canActivate: [ ClientUserAccessGuard ],
    loadChildren: () =>
      import('@app/modules/journey/journey.module').then(
        (m) => m.JourneyModule
      ),
  },
  {
    path: 'public',
    loadChildren: () =>
      import(
        '@app/modules/public/public.module'
        ).then((m) => m.PublicModule),
    data: {
      layout: LayoutType.Public,
    },
  },
  {
    path: 'solutions',
    canActivate: [ ClientUserAccessGuard ],
    loadChildren: () =>
      import('@app/modules/solutions/solutions.module').then(
        (m) => m.SolutionsModule
      ),
  },
  { path: '', component: NotFoundComponent, canActivate: [ HomePageSelectorGuard ], pathMatch: 'full' },
  { path: '**', component: NotFoundComponent, canActivate: [ AuthGuard ] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'ignore',
      paramsInheritanceStrategy: 'always',
      urlUpdateStrategy: 'eager',
      scrollPositionRestoration: 'disabled',
    }),
    NotFoundModule,
  ],
  exports: [ RouterModule ],
  providers: [ PursuitsFeatureGuard ],
})
export class AppRoutingModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    router.events
      .pipe(filter((e: Event): boolean => e instanceof Scroll))
      .subscribe(() => {
        setTimeout(() => viewportScroller.scrollToPosition([ 0, 0 ]), 0);
      });
  }
}
