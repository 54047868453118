import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { interceptorProviders } from '@app/core/interceptors';
import { ChipComponent } from '@app/shared/chip/chip.component';
import {
  PaginationComponent,
  SizeOptionsButtonComponent,
  SizeOptionsPopoverComponent,
} from '@app/shared/pagination';
import { PopoverComponent, PopoverService } from '@app/shared/popover';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { IMaskModule } from 'angular-imask';

import { BackToTopComponent } from './back-to-top/back-to-top.component';
import {
  BaseMultiselectorButtonComponent,
  BaseMultiselectorComponent,
  BaseMultiselectorInputComponent,
  BaseMultiselectorItemComponent,
  BaseMultiselectorPopoverComponent,
} from './base-multiselector';
import {
  BaseSelectorButtonComponent,
  BaseSelectorComponent,
  BaseSelectorInputComponent,
  BaseSelectorPopoverComponent,
} from './base-selector';
import {
  IconButtonComponent,
  IconTextButtonComponent,
  LinkButtonsGroupComponent,
  ResetDefaultButtonComponent,
} from './buttons';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import {
  CreatePlanningButtonComponent,
  CreatePlanningComponent,
  CreatePlanningPopoverComponent,
} from './create-planning';
import { DataTreeComponent } from './data-tree/data-tree.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { ErrorNoDataComponent } from './error-no-data/error-no-data.component';
import { ErrorComponent } from './error/error.component';
import { FieldSearchComponent } from './field-search/field-search.component';
import { HeaderOpportunitiesComponent } from './header-opportunities/header-opportunities.component';
import { HistoryFilterPipe } from './history/history-filter.pipe';
import { HistoryViewComponent } from './history/history-view/history-view.component';
import { HistoryComponent } from './history/history.component';
import {
  IndustrySelectorButtonComponent,
  IndustrySelectorComponent,
  IndustrySelectorInputComponent,
  IndustrySelectorPopoverComponent,
} from './industry-selector';
import {
  InfoButtonComponent,
  InfoComponent,
  InfoPopoverComponent,
} from './info';
import { InsightsComponent } from './insights';
import { LimitedInputComponent } from './limited-input/limited-input.component';
import { MaterialModule } from './material/material.module';
import {
  MetricChartColumnsComponent,
  MetricChartComponent,
  MetricChartScheduleComponent,
  MetricChartTableComponent,
} from './metric-chart';
import {
  MoreActionsButtonComponent,
  MoreActionsComponent,
  MoreActionsPopoverComponent,
} from './more-actions';
import { NotificationsQueueComponent } from './notifications-queue/notifications-queue.component';
import { PaperComponent } from './paper/paper.component';
import { CurrencySymbolPipe } from './pipes/currency-symbol.pipe';
import { EmptyPipe } from '../common/pipes/empty.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { FixedNumberPipe } from './pipes/fixed-number.pipe';
import { MetricUnitPipe } from './pipes/metric-unit.pipe';
import { NumberPeriodPipe } from './pipes/number-period.pipe';
import { ReplaceLineBreaksPipe } from './pipes/replace-line-breaks.pipe';
import { TimesPipe } from './pipes/times.pipe';
import { PriorityInfoComponent } from './priority-info';
import { PriorityInfoButtonComponent } from '@app/shared/priority-info';
import { PriorityInfoPopoverComponent } from '@app/shared/priority-info';
import {
  CurrencyButtonComponent,
  CurrencyComponent,
  CurrencyListComponent,
  CurrencyPopoverComponent,
  FilterCurrenciesPipe,
} from './tab-filters/currency';
import { CurrencyInputComponent } from './tab-filters/currency/currency-input/currency-input.component';
import { EstimatedRevenueComponent } from './tab-filters/estimated-revenue/estimated-revenue.component';
import {
  MetricButtonComponent,
  MetricComponent,
  MetricFilterPipe,
  MetricItemComponent,
  MetricListComponent,
  MetricPopoverComponent,
} from './tab-filters/metric';
import {
  PeerButtonComponent,
  PeerComponent,
  PeerItemComponent,
  PeerListComponent,
  PeerPopoverComponent,
} from './tab-filters/peer';
import { PriorityComponent } from './tab-filters/priority/priority.component';
import {
  RevenueButtonComponent,
  RevenueComponent,
  RevenueItemComponent,
  RevenuePopoverComponent,
} from './tab-filters/revenue';
import { SizeComponent } from './tab-filters/size/size.component';
import {
  TargetRevenueButtonComponent,
  TargetRevenueComponent,
  TargetRevenueInputComponent,
  TargetRevenuePopoverComponent,
} from './tab-filters/target-revenue';
import { FilterInputComponent } from './table-filters';
import { TaigaCdkModule } from './taiga-cdk/taiga-cdk.module';
import { UiBlockerComponent } from './ui-blocker/ui-blocker.component';
import { UploaderInputComponent } from './uploader-input/uploader-input.component';
import { ValidationErrorComponent } from './validation/validation-error.component';
import { VisualMultiselectorComponent } from './visual-multiselector/visual-multiselector.component';
import { WidgetComponent } from './widget/widget.component';
import { PeerInputComponent } from './tab-filters/peer/peer-input/peer-input.component';
import { DndGridComponent } from './dnd-grid/dnd-grid.component';
import { DndGridItemDirective } from './dnd-grid/dnd-grid-item.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NumberInputComponent } from './number-input/number-input.component';
import { UserInteractionModule } from '@app/shared/user-interaction/user-interaction.module';
import { HeaderNoticeBarComponent } from './header-notice-bar/header-notice-bar.component';
import { ToggleListComponent } from './toggle-list/toggle-list.component';
import { HistoryCustomCompaniesViewComponent } from './history/history-custom-companies-view/history-custom-companies-view.component';
import { TrendChartComponent } from '@app/common/components/trend-chart/trend-chart.component';
import { MetricsDashboardComponent } from './metrics-dashboard/metrics-dashboard.component';
import { KpiService } from './tab-filters/kpi/services/kpi.service';
import { KpiButtonComponent, KpiComponent, KpiItemComponent, KpiPopoverComponent } from './tab-filters/kpi';
import { KpiListComponent } from './tab-filters/kpi/kpi-list/kpi-list.component';
import { NotAvailablePipe } from '@app/shared/pipes/not-available.pipe';
import { DashboardChartComponent } from '@app/common/components/dashboard-chart/dashboard-chart.component';
import { NotProvidedPipe } from '@app/shared/pipes/not-provided.pipe';
import { LearnMoreLinkComponent } from '@app/common/components/learn-more-link/learn-more-link.component';
import { NewJourneysWidgetComponent } from './new-journeys-widget/new-journeys-widget.component';
import { SolutionFilterPipe } from './tab-filters/solution/solution-popover/solution-filter.pipe';
import { SolutionFavoriteFilterPipe } from './tab-filters/solution/solution-popover/solution-favorites-filter.pipe';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    RouterModule,
    IMaskModule,
    TaigaCdkModule,
    DigitOnlyModule,
    ReactiveFormsModule,
    FormsModule,
    RxReactiveFormsModule,
    DragDropModule,
    UserInteractionModule,
    DashboardChartComponent,
    TrendChartComponent,
    EmptyPipe,
    PaginationComponent,
    SizeOptionsButtonComponent,
    MetricUnitPipe,
    FilterInputComponent,
    MoreActionsComponent,
    MoreActionsButtonComponent,
    MoreActionsPopoverComponent,
    BaseSelectorComponent,
    BaseSelectorButtonComponent,
    BaseSelectorPopoverComponent,
    BaseSelectorInputComponent,
    DatepickerComponent,
    NotAvailablePipe,
    NotProvidedPipe,
    NumberInputComponent,
    CurrencyComponent,
    CurrencyButtonComponent,
    CurrencyPopoverComponent,
    CurrencyListComponent,
    FilterCurrenciesPipe,
    SolutionFilterPipe,
    SolutionFavoriteFilterPipe,
    CurrencyInputComponent,
    FieldSearchComponent,
    IconTextButtonComponent,
    IconButtonComponent,
    LimitedInputComponent,
    MetricsDashboardComponent,
    LearnMoreLinkComponent,
    NewJourneysWidgetComponent,
    FixedNumberPipe,
    NumberPeriodPipe,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RxReactiveFormsModule,
    MaterialModule,
    UserInteractionModule,
    BackToTopComponent,
    ErrorComponent,
    ChipComponent,
    ValidationErrorComponent,
    IconButtonComponent,
    IconTextButtonComponent,
    ResetDefaultButtonComponent,
    HeaderOpportunitiesComponent,
    PaperComponent,
    FieldSearchComponent,
    HistoryComponent,
    PaginationComponent,
    CurrencyComponent,
    PriorityComponent,
    SizeComponent,
    PeerComponent,
    RevenueComponent,
    RevenueItemComponent,
    TargetRevenueComponent,
    MetricComponent,
    ReplaceLineBreaksPipe,
    EmptyPipe,
    NotAvailablePipe,
    NotProvidedPipe,
    TimesPipe,
    CurrencySymbolPipe,
    ErrorNoDataComponent,
    FilterInputComponent,
    InfoComponent,
    InfoPopoverComponent,
    InfoButtonComponent,
    InsightsComponent,
    WidgetComponent,
    MetricChartComponent,
    MetricChartColumnsComponent,
    UiBlockerComponent,
    MetricUnitPipe,
    ConfirmationComponent,
    DatepickerComponent,
    CurrencyInputComponent,
    NotificationsQueueComponent,
    NumberPeriodPipe,
    BaseSelectorComponent,
    TaigaCdkModule,
    CreatePlanningComponent,
    DataTreeComponent,
    LimitedInputComponent,
    NumberInputComponent,
    BaseMultiselectorComponent,
    VisualMultiselectorComponent,
    BaseMultiselectorInputComponent,
    UploaderInputComponent,
    IndustrySelectorComponent,
    EstimatedRevenueComponent,
    PriorityInfoComponent,
    PriorityInfoButtonComponent,
    PriorityInfoPopoverComponent,
    MoreActionsComponent,
    MoreActionsButtonComponent,
    MoreActionsPopoverComponent,
    FilterPipe,
    LinkButtonsGroupComponent,
    DndGridComponent,
    DndGridItemDirective,
    HeaderNoticeBarComponent,
    ToggleListComponent,
    DashboardChartComponent,
    TrendChartComponent,
    MetricsDashboardComponent,
    KpiComponent,
    NewJourneysWidgetComponent
  ],
  declarations: [
    SizeOptionsPopoverComponent,
    PopoverComponent,
    BackToTopComponent,
    ErrorComponent,
    ChipComponent,
    ValidationErrorComponent,
    ResetDefaultButtonComponent,
    HeaderOpportunitiesComponent,
    PaperComponent,
    HistoryComponent,
    HistoryFilterPipe,
    HistoryViewComponent,
    PriorityComponent,
    SizeComponent,
    PeerComponent,
    PeerButtonComponent,
    PeerPopoverComponent,
    PeerListComponent,
    PeerItemComponent,
    RevenueComponent,
    RevenueButtonComponent,
    RevenuePopoverComponent,
    RevenueItemComponent,
    TargetRevenueComponent,
    TargetRevenueButtonComponent,
    TargetRevenuePopoverComponent,
    TargetRevenueInputComponent,
    MetricComponent,
    MetricButtonComponent,
    MetricPopoverComponent,
    MetricFilterPipe,
    MetricListComponent,
    MetricItemComponent,
    ReplaceLineBreaksPipe,
    TimesPipe,
    CurrencySymbolPipe,
    ErrorNoDataComponent,
    InfoComponent,
    InfoPopoverComponent,
    InfoButtonComponent,
    InsightsComponent,
    WidgetComponent,
    MetricChartComponent,
    MetricChartScheduleComponent,
    MetricChartTableComponent,
    MetricChartColumnsComponent,
    UiBlockerComponent,
    ConfirmationComponent,
    NotificationsQueueComponent,
    UploaderInputComponent,
    CreatePlanningComponent,
    CreatePlanningButtonComponent,
    CreatePlanningPopoverComponent,
    DataTreeComponent,
    BaseMultiselectorComponent,
    BaseMultiselectorButtonComponent,
    BaseMultiselectorItemComponent,
    BaseMultiselectorPopoverComponent,
    VisualMultiselectorComponent,
    BaseMultiselectorInputComponent,
    IndustrySelectorComponent,
    IndustrySelectorInputComponent,
    IndustrySelectorButtonComponent,
    IndustrySelectorPopoverComponent,
    EstimatedRevenueComponent,
    PriorityInfoComponent,
    PriorityInfoButtonComponent,
    PriorityInfoPopoverComponent,
    FilterPipe,
    PeerInputComponent,
    LinkButtonsGroupComponent,
    DndGridComponent,
    DndGridItemDirective,
    HeaderNoticeBarComponent,
    ToggleListComponent,
    HistoryCustomCompaniesViewComponent,
    KpiComponent,
    KpiButtonComponent,
    KpiItemComponent,
    KpiListComponent,
    KpiPopoverComponent,
  ],
  providers: [ PopoverService, KpiService, interceptorProviders ],
})
export class SharedModule {
}
