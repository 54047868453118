<app-learn-basics-button
  #handle
  class="learn-basics-popover__heading"
  [isOpened]="true"
  (click)="closePopover()"
></app-learn-basics-button>
<div
  #content
  class="learn-basics-popover__content"
  [class.learn-basics-popover__content_full]="isFullWidth"
  [ngStyle]="{
    width: isFullWidth || containerWidth !== 0 ? containerWidth + 'px' : 'auto'
  }"
>
  <nav class="learn-basics-popover__actions">
    <div class="learn-basics-popover__action">
      <a
        class="learn-basics-popover__action-link"
        routerLink="/training/videos"
        i18n
        (click)="
          amplitudeEventTracking('Learn the Basics - Video Tutorials Click')
        "
      >
        <span>Video Tutorials</span>
      </a>
    </div>
  </nav>
</div>
