<h3 *ngIf="companyName" class="text-h3" style="margin-bottom: 24px;">Account Overview for {{ companyName }}</h3>

<h3 [ngClass]="companyName ? 'metrics-dashboard__head_journey' : 'metrics-dashboard__head'">
  Financial Performance<span class="metrics-dashboard__date">{{
    periodText
  }}</span>
  <app-learn-more-link [topic]="learnMoreTopic"
                       (opened)="learnMoreOpened.emit($event)"
                       class="metrics-dashboard__learn_more"/>
</h3>

<div class="metrics-dashboard__content">
  <section class="metrics-dashboard__charts">
    <div class="metrics-dashboard__item">
      <app-dashboard-chart [metric]="revenueWidgetData.dashboard" [width]="width"></app-dashboard-chart>

      <app-trend-chart [data]="revenueWidgetData.trend" [width]="width"></app-trend-chart>

      <div *ngIf="revenueWidgetData.dashboard?.placeholder"
           style="text-align: center">
        Sorry, metric is not reported for specified criteria.
      </div>

    </div>
    <div class="metrics-dashboard__item">
      <app-dashboard-chart [metric]="incomeWidgetData.dashboard" [width]="width"></app-dashboard-chart>

      <app-trend-chart [data]="incomeWidgetData.trend" [width]="width"></app-trend-chart>

      <div *ngIf="incomeWidgetData.dashboard?.placeholder"
           style="text-align: center">
        Sorry, metric is not reported for specified criteria.
      </div>

    </div>
  </section>

  <div class="metrics-dashboard__info">
    <div
      *ngIf="absoluteData != null"
      class="metrics-dashboard__absolute dashboard-absolute"
    >
      <h6 class="dashboard-absolute__name">{{ absoluteData.name }}</h6>

      <div class="dashboard-absolute__data">
        <div class="dashboard-absolute__value">
          <span>{{ currencySymbol }}</span
          >{{
            absoluteData.value
              | currency: absoluteData.currencyCode:'':'1.0-0'
              | empty: '-'
          }}
        </div>
        <div
          *ngIf="absoluteData.value != null"
          class="dashboard-absolute__code"
        >
          Mil-{{ absoluteData.currencyCode }}
        </div>
        <div class="dashboard-absolute__date">{{ absoluteData.date | date : 'MMMM yyyy' : '+0000' }}</div>
      </div>
    </div>

    <ul class="metrics-dashboard__legend">
      <li *ngFor="let row of prepareLegendData();">
        <span [style.background-color]="row?.color"></span
        >{{ row?.name }}
      </li>
    </ul>
  </div>
</div>
