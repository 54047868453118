<ng-container *tuiLet="portal$ | async as portal">
  <div *ngIf="portal != null" class="footer__portal">
    <ng-container [cdkPortalOutlet]="portal"></ng-container>
  </div>
  <div
    class="footer__copyright"
    [class.footer__copyright_with-portal]="portal != null"
    i18n
  >
    Copyright © {{ currentYear }} FinListics Solutions. All rights reserved.
    Financial data presented in the system is according to the provided
    companies' reporting.
    <div>
      <a
        [routerLink]="['/public', 'privacy-policy']"
        target="_blank"
        (click)="amplitudeTracking('Privacy Policy Click')"
        >Privacy Policy</a
      >
      <span>|</span>
      <a
        [routerLink]="['/public', 'terms-of-use']"
        target="_blank"
        (click)="amplitudeTracking('Terms of Use Click')"
        >Terms of Use</a
      >
    </div>
  </div>
</ng-container>
