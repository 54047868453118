import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AddedCompaniesData,
  AddedCompaniesFilter,
  CompaniesByRevenue,
  Company,
  CompanyPeriods,
  CompanyProfile,
  CompanyWithCustomGoalsAndStrategies,
  CompanyWithCustomGoalsAndStrategiesFilter,
  LegacyCompany,
  OverviewTrendItem,
} from '@app/core/models/company.model';
import { ApiService } from '@app/core/services/api.service';
import { format } from 'date-fns';
import { Dictionary, isNil, omitBy, toPairs } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { CACHE_EXPIRE_DISTANCE } from '../constants';
import { Currency } from '../models/currency.model';
import { FullIndustryDataSet, IndustryCode, IndustryReportsGroup, } from '../models/industry.model';
import { Insights } from '../models/insights.model';
import { MetricChartView } from '../models/metric-chart.model';
import { RevenueRange } from '../models/revenue.model';
import { ReportData, ReportStatus, } from '@app/modules/usage-reports/usage-reports.model';
import { CompanyPresentationBackData, PrivateCompanyPresentationBackData } from '@app/modules/common/presentation/presentation.model';
import { ListPage } from './client.service';
import { BaseList, UUID } from '../models/common.model';
import { timezoneCorrection } from '../utils/date.util';
import { NamedBlob } from '@app/core/models/api.model';
import { parse as parseContentDisposition } from '@tinyhttp/content-disposition';

export type CustomSorter = {
  property: string;
  direction: SortCustomDirection;
};
export type SortCustomDirection = 'Ascending' | 'Descending' | '';

type CacheType = {
  data: Company[];
  time: Date;
};

interface CompanyBriefDataResponseApi {
  brief: string;
}

interface CompanyBriefResponseApi {
  companyUid: string;
  brief: string;
}

/**
 * @deprecated
 *  use instead {@link CompanyRepository}
 */
@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private _companiesSearchCache: Dictionary<CacheType> = {};
  private _privateCompaniesSearchCache: Dictionary<CacheType> = {};

  constructor(private apiService: ApiService) {
  }

  /**
   * Get Company list by query
   *
   * @param query - filter query
   * @param force
   * @param useCache
   * @param blockCid
   * @param token - token for unauthorized users
   */
  getCompanies(
    query: {
      term: string,
      industryCategories?: UUID[]
    },
    force = false,
    useCache = false,
    blockCid?: string,
    token?: string
  ): Observable<Company[]> {
    const cacheKey = `${ query.term }:${ query.industryCategories }`
    if (
      this._companiesSearchCache[cacheKey] != null &&
      (Date.now() - this._companiesSearchCache[cacheKey].time.getTime() <
        CACHE_EXPIRE_DISTANCE ||
        useCache) &&
      !force
    ) {
      return of(this._companiesSearchCache[cacheKey].data);
    }

    const paramsObj = {
      searchQuery: query.term,
      categoryUids: query.industryCategories ?? [],
      token,
    };

    if (!token) {
      delete paramsObj.token;
    }

    return this.apiService
      .get<Company[]>(
        'company/company',
        {
          params: new HttpParams({
            fromObject: paramsObj,
          }),
        },
        blockCid
      )
      .pipe(
        tap((result: Company[]) => {
          this._companiesSearchCache[cacheKey] = {
            data: result,
            time: new Date(),
          };
        }),
        catchError(() => of([]) as Observable<Company[]>)
      );
  }

  getCompaniesByRevenue(
    body: Dictionary<string | number>,
    blockCid?: string
  ): Observable<CompaniesByRevenue> {
    return this.apiService
      .get<CompaniesByRevenue>(
        'company/Statistic/revenue',
        {
          params: new HttpParams({
            fromObject: body,
          }),
        },
        blockCid
      )
      .pipe(catchError(() => of(null)));
  }

  getPrivateCompanies(
    query: string,
    force = false,
    useCache = false,
    blockCid?: string
  ): Observable<Company[]> {
    if (
      this._privateCompaniesSearchCache[query] != null &&
      (Date.now() - this._privateCompaniesSearchCache[query].time.getTime() <
        CACHE_EXPIRE_DISTANCE ||
        useCache) &&
      !force
    ) {
      return of(this._privateCompaniesSearchCache[query].data);
    }

    return this.apiService
      .get<Company[]>(
        'company/Company/private-companies',
        {
          params: new HttpParams({
            fromObject: {
              searchQuery: query,
            },
          }),
        },
        blockCid
      )
      .pipe(
        tap((result: Company[]) => {
          this._privateCompaniesSearchCache[query] = {
            data: result,
            time: new Date(),
          };
        }),
        catchError(() => of([]) as Observable<Company[]>)
      );
  }

  getPrivateCompany(
    uid: string,
    force = false,
    useCache = false,
    blockCid?: string
  ): Observable<Company> {
    if (
      this._privateCompaniesSearchCache[uid] != null &&
      (Date.now() - this._privateCompaniesSearchCache[uid].time.getTime() <
        CACHE_EXPIRE_DISTANCE ||
        useCache) &&
      !force
    ) {
      return of(this._privateCompaniesSearchCache[uid].data[0]);
    }

    return this.apiService
      .get(
        'company/Company/private-company',
        {
          params: new HttpParams({
            fromObject: {
              uid,
            },
          }),
        },
        blockCid
      )
      .pipe(
        tap((result: Company) => {
          this._privateCompaniesSearchCache[uid] = {
            data: [ result ],
            time: new Date(),
          };
        }),
        catchError(() => of(null))
      );
  }

  getUserPrivateCompany(
    userUid: string,
    uid: string,
    force = false,
    useCache = false,
    blockCid?: string
  ): Observable<Company> {
    const cacheUid = `${ userUid }_${ uid }`;
    if (
      this._privateCompaniesSearchCache[cacheUid] != null &&
      (Date.now() - this._privateCompaniesSearchCache[cacheUid].time.getTime() <
        CACHE_EXPIRE_DISTANCE ||
        useCache) &&
      !force
    ) {
      return of(this._privateCompaniesSearchCache[cacheUid].data[0]);
    }

    return this.apiService
      .get(`company/users/${ userUid }/private-company/${ uid }`, blockCid)
      .pipe(
        tap((result: Company) => {
          this._privateCompaniesSearchCache[cacheUid] = {
            data: [ result ],
            time: new Date(),
          };
        }),
        catchError(() => of(null))
      );
  }

  setUserPrivateCompanyData(
    userUid: string,
    companyUid: string,
    industryRevenueGroupUid?: string,
    blockCid?: string
  ): Observable<boolean> {
    return this.apiService
      .put(
        `company/users/${ userUid }/private-company/${ companyUid }`,
        {
          industryRevenueGroupUid,
        },
        blockCid
      )
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  getCompanyProfile(
    companyUid: string,
    industryRevenueGroupUid: string | null | undefined,
    blockUid?: string
  ): Observable<CompanyProfile> {
    const params = new HttpParams({
      fromObject: omitBy({ companyUid, industryRevenueGroupUid }, isNil),
    });

    return this.apiService
      .get<CompanyProfile>(
        'company/company/profile',
        {
          params,
        },
        blockUid
      )
      .pipe(catchError(() => of(null)));
  }

  getPrivateCompanyProfile(
    companyUid: string,
    userUid: string,
    blockUid?: string
  ): Observable<CompanyProfile> {
    return this.apiService
      .get<CompanyProfile>(
        `company/users/${ userUid }/private-company/${ companyUid }/profile`,
        blockUid
      )
      .pipe(catchError(() => of(null)));
  }

  getReportLinks(
    fullParams: {
      companyUid: string;
      currencyUid: string;
      industryUid?: string;
      subIndustryUid?: string;
      industryRevenueGroupUid?: string;
      regionUid?: string;
      financialAnalysisType?: MetricChartView;
    },
    cId?: string
  ): Observable<IndustryReportsGroup[]> {
    const params = new HttpParams({
      fromObject: omitBy(fullParams, isNil),
    });

    return this.apiService
      .get<{ groups: IndustryReportsGroup[] }>(
        'company/company/reports',
        {
          params,
        },
        cId
      )
      .pipe(
        map(({ groups }: { groups: IndustryReportsGroup[] }) => groups),
        catchError(() => of([]))
      );
  }

  getCompanyByLegacyId(
    legacyId: string,
    blockCid?: string
  ): Observable<LegacyCompany> {
    return this.apiService
      .get<LegacyCompany>(
        'company/company/for-legacy',
        {
          params: new HttpParams({
            fromObject: {
              companyId: legacyId,
            },
          }),
        },
        blockCid
      )
      .pipe(catchError(() => of(null)));
  }

  getLastPeriodDates(
    companyUid: string,
    blockCid?: string
  ): Observable<CompanyPeriods> {
    return this.apiService
      .get<CompanyPeriods>(
        'company/metric/company-last-reports-dates',
        {
          params: new HttpParams({
            fromObject: {
              companyUid,
            },
          }),
        },
        blockCid
      )
      .pipe(
        map(
          (periods: CompanyPeriods) =>
            ({
              lastAnnualReportDate:
                periods.lastAnnualReportDate != null
                  ? timezoneCorrection(periods.lastAnnualReportDate).toLocaleString('en-US', {
                    timeZone: 'UTC',
                    month: 'long',
                    year: 'numeric'
                  })
                  : periods.lastAnnualReportDate,
              lastLtmReportDate:
                periods.lastLtmReportDate != null
                  ? format(
                    timezoneCorrection(periods.lastLtmReportDate),
                    'MMMM yyyy'
                  )
                  : periods.lastLtmReportDate,
            } as CompanyPeriods)
        ),
        catchError(() => of(null))
      );
  }

  getFinancialHighlights(
    companyUid: string,
    currencyUid: string,
    financialAnalysisType?: MetricChartView,
    blockCid?: string
  ): Observable<Insights> {
    return this.apiService
      .get<Insights>(
        'company/Company/financial-highlights',
        {
          params: new HttpParams({
            fromObject: {
              companyUid,
              currencyUid,
              financialAnalysisType,
            },
          }),
        },
        blockCid
      )
      .pipe(catchError(() => of(null)));
  }

  getCompanyRevenueRange(companyUid: string): Observable<RevenueRange> {
    const params = new HttpParams({
      fromObject: {
        companyUid,
      },
    });

    return this.apiService
      .get<RevenueRange>(
        'company/company/industry-revenue-group',
        {
          params,
        },
        true
      )
      .pipe(catchError(() => of(null)));
  }

  getCompanyOverviewTrends(
    companyUid: string,
    industryRevenueGroupUid: string,
    metrics: string[],
    financialAnalysisType?: MetricChartView,
    blockCid?: string
  ): Observable<Dictionary<OverviewTrendItem>> {
    return this.apiService
      .get<{ items: OverviewTrendItem[] }>(
        'company/metric/metric-trends-info',
        {
          params: new HttpParams({
            fromObject: omitBy(
              {
                companyUid,
                industryRevenueGroupUid,
                ...metrics.reduce(
                  (result: Dictionary<string>, item: string, index: number) => {
                    result[`metricUids[${ index }]`] = item;
                    return result;
                  },
                  {}
                ),
                financialAnalysisType,
              },
              isNil
            ),
          }),
        },
        blockCid
      )
      .pipe(
        map((data) =>
          data.items.reduce(
            (
              result: Dictionary<OverviewTrendItem>,
              item: OverviewTrendItem
            ) => {
              result[item.uid] = item;
              return result;
            },
            {}
          )
        ),
        catchError(() => of({}))
      );
  }

  generatePresentationLink(
    company: Company,
    currency: Currency,
    industryData?: FullIndustryDataSet
  ): string {
    if (company?.industry.category.code !== IndustryCode.Healthcare) {
      let initialParamsSet: Dictionary<string> = {
        companyUid: company?.uid,
      };

      if (industryData != null) {
        initialParamsSet.industryUid = industryData.industry.uid;
        initialParamsSet.subIndustryUid = industryData.subIndustry?.uid;
        initialParamsSet.industryRevenueGroupUid = industryData.revenue.uid;
        initialParamsSet.regionUid = industryData.region.uid;
      }

      if (company?.currencyUid !== currency?.uid) {
        initialParamsSet.currencyUid = currency.uid;
      }

      initialParamsSet = omitBy(initialParamsSet, isNil);

      return `${ environment.apiGateway }/company/company/generate-ppt?${ toPairs(
        initialParamsSet
      )
        .map(
          (set: [ string, string ]) => `${ set[0] }=${ encodeURIComponent(set[1]) }`
        )
        .join('&') }`;
    } else {
      return null;
    }
  }

  runPresentationGeneration(
    params: CompanyPresentationBackData,
    clientUid: string,
    companyUid: string,
    blockCid?: string
  ): Observable<{ status: ReportStatus; messageId: string, errorDetails?: string }> {
    return this.apiService
      .post<{ status: ReportStatus; messageId: string }>(
        `reporting/clients/${ clientUid }/company/${ companyUid }/presentation`,
        params,
        blockCid
      )
      .pipe(
        catchError((errorObject: HttpErrorResponse) =>
          of({
            status: ReportStatus.Failed,
            messageId: null,
            errorDetails: errorObject.error?.detail ?? 'Sorry, something went wrong. Please reload the page or try again later.'
          })
        )
      );
  }


  runPrivateCompaniesPresentationGeneration(
    params: PrivateCompanyPresentationBackData,
    clientUid: string,
    privateCompanyUid: string,
    blockCid?: string
  ): Observable<{ status: ReportStatus; messageId: string, errorDetails?: string }> {
    return this.apiService
      .post<{ status: ReportStatus; messageId: string }>(
        `reporting/clients/${ clientUid }/private-company/${ privateCompanyUid }/presentation`,
        params,
        blockCid
      )
      .pipe(
        catchError((errorObject: HttpErrorResponse) =>
          of({ status: ReportStatus.Failed, messageId: null, errorDetails: errorObject.error.detail }))
      );
  }

  getFilesDownloadList(messagesIds: string[]): Observable<ReportData[]> {
    return this.apiService
      .get<ReportData[]>('reporting/report/get-ready-documents', {
        params: new HttpParams({
          fromObject: {
            messagesIds,
          },
        }),
      })
      .pipe(catchError(() => of([])));
  }

  /**
   * Get specific report page as svg image.
   */
  getPresentationSvgPreview(
    userUid: string,
    messageUid: string,
    slideIndex: number,
    blockCid?: string
  ): Observable<string> {

    return this.apiService
      .get<string>(`reporting/users/${ userUid }/presentation/${ messageUid }/slide/${ slideIndex }/svg`, blockCid);
  }

  downloadFiles(reports: ReportData[]): void {
    if (reports.length === 1) {
      this.apiService.downloadByLink(reports[0].linkToFile);
    } else {
      for (const report of reports) {
        this.apiService.downloadByLink(report.linkToFile);
      }
    }
  }

  getAddedCompanies(
    userUid: string,
    page: ListPage,
    sort?: CustomSorter[],
    filter?: AddedCompaniesFilter,
    blockCid?: string
  ): Observable<BaseList<AddedCompaniesData>> {
    return this.apiService
      .post<BaseList<AddedCompaniesData>>(
        `company/users/${ userUid }/custom-companies/query`,
        {
          page,
          sort,
          filter,
        },
        blockCid,
        -1
      )
      .pipe(
        catchError(() =>
          of({
            items: [] as AddedCompaniesData[],
            totalCount: 0,
          } as BaseList<AddedCompaniesData>)
        )
      );
  }

  removeAddedCompany(
    userUid: string,
    uid: string,
    blockCid?: string
  ): Observable<boolean> {
    return this.apiService
      .delete<{ uid: string }>(
        `company/users/${ userUid }/custom-companies/${ uid }`,
        blockCid
      )
      .pipe(
        map((data) => data.uid != null),
        catchError(() => of(false))
      );
  }

  // API for get and update Company Brief data

  getCompanyBriefData(
    companyUid: string,
    blockId?: string
  ): Observable<string> {
    return this.apiService
      .get<CompanyBriefDataResponseApi>(
        `company/companies/${ companyUid }/extras`,
        blockId
      )
      .pipe(map((item) => item.brief));
  }

  updateCompanyBriefData(
    companyUid: string,
    brief: string,
    blockId?: string
  ): Observable<CompanyBriefDataResponseApi> {
    return this.apiService.put<CompanyBriefDataResponseApi>(
      `company/companies/${ companyUid }/extras`,
      {
        brief,
      },
      blockId
    );
  }

  //

  /**
   * Get company brief in Markdown format
   */
  getCompanyBrief(companyUid: string, blockId?: string): Observable<string> {
    const path = `company/Company/brief`;

    const options = {
      params: new HttpParams({
        fromObject: {
          companyUid,
        },
      }),
    };

    return this.apiService
      .get<CompanyBriefResponseApi>(path, options, blockId)
      .pipe(map((response) => response.brief));
  }

  /**
   * Get company brief as PDF document
   */
  getCompanyBriefPdf(
    companyUid: UUID, companyName: string, clientUid: UUID, blockCid?: string
  ): Observable<NamedBlob> {

    const path = `charting/companies/${ companyUid }/custom-goals-and-strategies/pdf?clientUid=${ clientUid }`;

    const response = this.apiService.get<HttpResponse<Blob>>(
      path,
      { responseType: 'blob', observe: 'response' },
      blockCid,
    );

    return response.pipe(
      map(it => {

        let fileName: string;

        try {
          const contentDispositionValue = it.headers.get('content-disposition');
          fileName = parseContentDisposition(contentDispositionValue)?.parameters?.filename as string;
        } catch (ex) {
          fileName = `${ companyName } Goals and Strategies ClientIQ Report_${ format(new Date(), 'MM-dd-yyyy') }.pdf`
        }

        return {
          name: fileName,
          blob: it.body
        };
      })
    );
  }

  /**
   * Get list of companies (accounts) that have custom goals and strategies defined
   */
  listCompaniesWithCustomGoalsAndStrategies(
    filter?: CompanyWithCustomGoalsAndStrategiesFilter,
    sort?: CustomSorter[],
    page?: ListPage,
    blockCid?: string
  ): Observable<BaseList<CompanyWithCustomGoalsAndStrategies>> {
    return this.apiService
      .post<BaseList<CompanyWithCustomGoalsAndStrategies>>(
        `company/companies/query-with-brief`,
        {
          filter,
          sort,
          page,
        },
        blockCid,
        -1
      )
      .pipe(
        catchError(() => of({
          items: [] as CompanyWithCustomGoalsAndStrategies[],
          totalCount: 0,
        } as BaseList<CompanyWithCustomGoalsAndStrategies>))
      );
  }
}
