<section class="tree-diagram">
  <div
    class="tree-diagram__head"
    [class.tree-diagram__head_empty]="isDataEmpty"
    [class.tree-diagram__head_no-data]="dataItems.length === 0"
  >
    <div class="tree-diagram__head-item">
      <ng-container *ngIf="!isDataEmpty; else elseText">
        <div *ngIf="diagramName">{{ diagramName }}:</div>
        <div>{{ data.name }}</div>
      </ng-container>
      <ng-template #elseText>
        No {{ diagramName }} Selected
      </ng-template>
    </div>
  </div>
  <div
    class="tree-diagram__data"
    [class.tree-diagram__data_empty]="isDataEmpty"
  >
    <div
      *ngFor="let item of dataItems"
      class="tree-item"
    >
      <span class="tree-item__connector"></span>
      <div class="tree-item__content">
        <span class="tree-item__text">{{ item.name }}</span>
        <fnl-info
          *ngIf="item.description"
          [hasContent]="true"
          class="tree-diagram__info"
        >
          <div class="tree-diagram__info-content">
            {{ item.description }}
          </div>
        </fnl-info>
      </div>
      <div class="tree-item__children">
        <div
          *ngFor="let subItem of item.children"
          class="tree-sub-item"
        >
          <span class="tree-sub-item__connector"></span>
          <div class="tree-sub-item__content">
            <span class="tree-sub-item__text">{{ subItem.name }}</span>
            <fnl-info
              *ngIf="subItem.description"
              [hasContent]="true"
              class="tree-diagram__info"
            >
              <div class="tree-diagram__info-content" [innerText]="subItem.description">
              </div>
            </fnl-info>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
