/* eslint-disable @typescript-eslint/naming-convention */
import { ConnectedPosition } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, HostBinding, OnInit, ViewChild, } from '@angular/core';
import { MatLegacyTooltip as MatTooltip } from '@angular/material/legacy-tooltip';
import { User, UserSettingCode } from '@app/core/models/user.model';
import { AmplitudeService } from '@app/api/amplitude/amplitude.service';
import { UserService } from '@app/core/services/user.service';
import { UserSettingsService } from '@app/core/services/user-settings.service';
import { ClientFeatureCode } from '@app/core/models/client.model';
import { PopoverService } from '@app/shared/popover';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { ListPopoverComponent } from './list-popover/list-popover.component';
import { Router } from '@angular/router';
import { ActivityTrackerService, TrackingCategory } from '@app/api/tracking/activity-tracker.service';
import { JourneyType } from '@app/core/services/journey.api.service';

@Component({
  selector: 'app-base-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  @ViewChild('recentlyTooltip') recentlyTooltip: MatTooltip;
  @ViewChild('favoritesTooltip') favoritesTooltip: MatTooltip;

  @HostBinding('class.header-wrapper') isWrapper = true;

  readonly defaultLinkToLogo = '/assets/images/logo.svg';
  readonly clientLogoDictionary = {
    IBM: '/assets/images/logo-product-CBV.svg',
  };

  readonly TrackingCategory = TrackingCategory;
  readonly JourneyType = JourneyType;

  linkLogo$: Observable<string>;
  clientCustomLogoUrl$: Observable<string>;
  userSettingLoad$: Observable<boolean>;
  customLogoEnabled$: Observable<boolean>;

  constructor(
    private popoverService: PopoverService,
    private _router: Router,
    private _userService: UserService,
    private _amplitudeService: AmplitudeService,
    private _activityTrackerService: ActivityTrackerService,
    private _userSettingsService: UserSettingsService,
  ) {
  }

  get hasUserAccess(): Observable<boolean> {
    return this._userService.hasUserAccess();
  }

  get onLearningRoute(): boolean {
    return this._router.url.startsWith('/training');
  }

  ngOnInit(): void {
    this.userSettingLoad$ = this._userSettingsService.getUserSettings()
      .pipe(
        map((items) => items.length !== 0)
      );

    this.linkLogo$ = this._userService.getCurrentUser()
      .pipe(
        map((user: User) => this.clientLogoDictionary[user.clientCode] || this.defaultLinkToLogo)
      );

    this.clientCustomLogoUrl$ = this._userService.getCurrentUser()
      .pipe(
        map((user) => user?.clientCustomLogo
          ? `${ environment.apiGateway }/identity/clients/${ user.clientUid }/logo?${ Date.now() }`
          : null
        )
      );

    this.customLogoEnabled$ = combineLatest([
      this._userSettingsService.getUserSettings(),
      this.clientCustomLogoUrl$
    ]).pipe(
      map(([ settings, logoUrl ]) => {
        const logoEnabled = settings.find(it => it.code === UserSettingCode.DisplayCustomLogo)?.value === 'true';
        const logoLoaded = logoUrl !== null;
        return logoLoaded && logoEnabled;
      }),
    );
  }

  openFavoritesPopover(element: HTMLElement) {
    this.favoritesTooltip.hide();
    this.popoverService.open(ListPopoverComponent, element, {
      data: { isFavorites: true },
      positions: this.popoverPositions(1),
    });
    this._amplitudeService.amplitudeEvent('Favorites View', {
      scope: 'All Favorites',
    });
  }

  amplitudeEventTracking(nameEvent: string): void {
    this._amplitudeService.amplitudeEvent(nameEvent);
  }

  pageView(pageType: TrackingCategory): void {
    this._activityTrackerService.registerPage({
      category: pageType
    });
  }

  private popoverPositions(offsetX = 0): ConnectedPosition[] {
    return [
      {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'top',
        offsetY: 41,
        offsetX,
      },
    ];
  }
}
